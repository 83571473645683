import { css, StyleSheet } from 'aphrodite/no-important'
import { Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import React from 'react'
import Headline from './Headline'

export interface TeaserProps {
    headline: string
    text: string
    link: string
    image: FixedObject
}

const Teaser: React.FC<TeaserProps> = ({ headline, text, link, image }: TeaserProps): JSX.Element => (
    <Link to={link} className={css(styles.Teaser)}>
        <Img className={css(styles.backgroundImage)} fixed={image} />
        <div className={css(styles.text)}>
            <Headline type="h3" text={headline} />
            <div className={css(styles.copy)}>{text}</div>
        </div>
    </Link>
)

const styles = StyleSheet.create({
    Teaser: {
        color: '#000',
        display: 'flex',
        width: '100%',
        position: 'relative',
        textDecoration: 'none',

        ':after': {
            content: '""',
            display: 'block',
            paddingTop: '100%',
        },
    },
    backgroundImage: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
        position: 'unset !important',
    },
    text: {
        position: 'absolute',
        flexDirection: 'column',
        left: 0,
        bottom: 15,
        padding: '20px 40px',
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        width: '100%',
        minHeight: 110,
        justifyContent: 'center',
    },
    copy: {
        textAlign: 'center',
        fontSize: 18,
        lineHeight: 1.4,
    },
})

export default Teaser
