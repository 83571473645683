import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'
import Headline from './Headline'
import Teaser, { TeaserProps } from './Teaser'

export interface TeaserBlockProps {
    headline: string
    teasers: TeaserProps[]
}

const TeaserBlock: React.FC<TeaserBlockProps> = ({ headline, teasers }: TeaserBlockProps): JSX.Element => (
    <div className={css(styles.TeaserBlock)}>
        <Headline text={headline} type="h2" horizontalLine />

        <div className={css(styles.teasers)}>
            {teasers.map((teaser: TeaserProps) => (
                <Teaser key={teaser.headline} {...teaser} />
            ))}
        </div>
    </div>
)

const styles = StyleSheet.create({
    TeaserBlock: {
        flexDirection: 'column',
        width: '100%',
        maxWidth: 1280,
        marginBottom: 100,
        padding: '0 40px',

        '@media (max-width: 1023px)': {
            padding: '0 20px',
        },
    },
    teasers: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: '1fr',
        gridColumnGap: 15,

        '@media (max-width: 1023px)': {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridColumnGap: 0,
            gridTemplateRows: '1fr 1fr 1fr',
            gridRowGap: 20,
        },
    },
})

export default TeaserBlock
