import { graphql } from 'gatsby'
import { FixedObject, FluidObject } from 'gatsby-image'
import React from 'react'
import Content from '../components/Content'
import Hero from '../components/Hero'
import Stage from '../components/Stage'
import TeaserBlock from '../components/TeaserBlock'
import Default from '../templates/default'

export interface IndexPageProps {
    data: {
        stageImage: {
            childImageSharp: {
                fluid: FluidObject
            }
        }
        teaserUnsereAngebote: {
            childImageSharp: {
                fixed: FixedObject
            }
        }
        teaserUnsereGeschichten: {
            childImageSharp: {
                fixed: FixedObject
            }
        }
        teaserUeberUns: {
            childImageSharp: {
                fixed: FixedObject
            }
        }
    }
}

const IndexPage = ({ data }: IndexPageProps): JSX.Element => {
    const teasers = [
        {
            headline: 'Unsere Angebote',
            text: 'Ausgefallene Workshops, Kurse und Events für Euren JGA. ',
            link: '/unsere-angebote/',
            image: data.teaserUnsereAngebote.childImageSharp.fixed,
        },
        {
            headline: 'Unsere Geschichten',
            text: 'Ein Blick hinter die Kulissen und von glücklichen Brides to be',
            link: '/unsere-geschichten/',
            image: data.teaserUnsereGeschichten.childImageSharp.fixed,
        },
        {
            headline: 'Über uns',
            text: 'Erfahre mehr über das Team und die Idee hinter Bride Surprise',
            link: '/ueber-uns/',
            image: data.teaserUeberUns.childImageSharp.fixed,
        },
    ]

    return (
        <Default>
            <Content align="center">
                <Stage
                    headline="JGA planen leicht gemacht"
                    text="Du bist auf der Suche nach ausgefallenen Ideen für den JGA Eurer zukünftigen Braut? Dann bist Du bei uns genau richtig."
                    linkTarget="/unsere-angebote/"
                    linkText="Zu unseren Angeboten"
                    image={data.stageImage.childImageSharp}
                />

                <Hero />

                <TeaserBlock headline="LOS GEHT'S" teasers={teasers} />
            </Content>
        </Default>
    )
}

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "stage/index.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 560, quality: 75, cropFocus: NORTH) {
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
        teaserUnsereAngebote: file(relativePath: { eq: "teaser/unsere-angebote.jpg" }) {
            childImageSharp {
                fixed(width: 333, quality: 75) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    aspectRatio
                    width
                    height
                    originalName
                }
            }
        }
        teaserUnsereGeschichten: file(relativePath: { eq: "teaser/unsere-geschichten.jpg" }) {
            childImageSharp {
                fixed(width: 333, quality: 75) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    aspectRatio
                    width
                    height
                    originalName
                }
            }
        }
        teaserUeberUns: file(relativePath: { eq: "teaser/ueber-uns.jpg" }) {
            childImageSharp {
                fixed(width: 333, quality: 75) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    aspectRatio
                    width
                    height
                    originalName
                }
            }
        }
    }
`

export default IndexPage
