import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'
import Copy from './Copy'
import Headline from './Headline'
import StyledLink from './StyledLink'

const Hero: React.FC = (): JSX.Element => (
    <div className={css(styles.Hero)}>
        <Headline type="h1" text="WILLKOMMEN BEI BRIDE SURPRISE" />

        <Copy centered>
            Es beginnt alles mit den fünf Worten „Willst Du meine Trauzeugin sein?“ und endet für viele in einem
            stressvollen Balanceakt aus Organisation und Erwartungserfüllung. Nicht jede von uns trägt eine kleine
            Projektmanagerin in sich, die einen perfekten Junggesellinnenabschied mit Leichtigkeit planen und umsetzen
            kann. Wir von Bride Surprise helfen Euch, den großen Tag vor der Hochzeit mit ausgefallenen Events
            auszurichten. Vom Frühstück über Flower-Crown Workshops, Shootings bis hin zur Gin-Verkostung. Wir haben für
            jedes Budget und jede Braut das Passende dabei. Und falls nicht, dann finden wir eine Lösung.
        </Copy>

        <StyledLink linkTarget="/kontakt/">Starte heute noch mit der Planung</StyledLink>
    </div>
)

const styles = StyleSheet.create({
    Hero: {
        maxWidth: 750,
        width: '100%',
        marginBottom: 80,
        flexDirection: 'column',
        alignItems: 'center',

        '@media (max-width: 1023px)': {
            padding: '0 20px',
        },
    },
    text: {
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 1.4,
        fontWeight: 300,
        marginBottom: 30,
    },
})

export default Hero
